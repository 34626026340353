jQuery(document).on('turbolinks:load', () => {
  jQuery('.dismiss-notification').each((i, el) => {
    jQuery(el).on('click', event => {
      event.preventDefault();

      jQuery
        .ajax({
          url: jQuery(el).attr('href'),
          method: 'PUT',
          data: {
            notification: {
              dismissed: true
            }
          }
        })
        .then(() => {
          jQuery(el)
            .parents('.notification')
            .remove();

          if (jQuery('.notifications').children().length === 0) {
            jQuery('.notifications').html(
              '<p class="mb-0">There are currently no notifications.</p>'
            );
          }
        });
    });
  });
});
