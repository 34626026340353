require('bootstrap/dist/js/bootstrap');

jQuery(document).on('turbolinks:load', () => {
  const $withdrawalModal = $('#withdrawal-modal');
  $withdrawalModal.modal({
    show: false
  });

  const $showLink = $('#show-withdrawal-modal');

  $showLink.on('click', event => {
    event.preventDefault();

    const studentName = $showLink
      .parents('tr')
      .find('.student-name')
      .text();
    const scholarshipId = $showLink.parents('tr').data('scholarship-id');
    const quarterlyEnrollmentId = $showLink
      .parents('tr')
      .data('quarterly-enrollment-id');

    $withdrawalModal.find('h5').text('Exit Form for ' + studentName);
    console.log($showLink.parents('tr').data('scholarship-id'));
    console.log(scholarshipId);
    console.log($withdrawalModal.find('.js-withdrawal-scholarship-id'));
    $withdrawalModal.find('.js-withdrawal-scholarship-id').val(scholarshipId);
    $withdrawalModal
      .find('.js-withdrawal-quarterly-enrollment-id')
      .val(quarterlyEnrollmentId);

    $withdrawalModal.modal('show');
  });
});
