var handleClear = function() {
  jQuery('.search input[type=search]').on('search', () => {
    const val = jQuery('.search input[type=search]').val();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('search', val);
    window.location.search = urlParams.toString();
  });
};

jQuery(document).on('turbolinks:load', handleClear);
