const uuidv1 = require('uuid/v1');
const guardianFields = () => {
  const toggleFields = e => {
    const form = e.target.form;
    const $checkbox = jQuery(e.target);
    const $target = jQuery(`#${$checkbox.data('target')}`, form);
    $target.toggleClass('d-none');
  };

  jQuery('.js-guardian-checkbox').change(toggleFields);

  jQuery(document).on('cocoon:before-insert', function(
    e,
    insertedItem,
    originalEvent
  ) {
    const id = uuidv1();
    jQuery('.js-guardian-subform', insertedItem).attr(
      'id',
      `js-guardian-${id}-form`
    );
    jQuery('.js-guardian-checkbox', insertedItem).data(
      'target',
      `js-guardian-${id}-form`
    );
    jQuery('.js-guardian-checkbox', insertedItem).change(toggleFields);

    jQuery('.selectize', insertedItem).selectize({});
    jQuery('.masked-phone', insertedItem).mask('(000) 000-0000');
  });
};

jQuery(document).on('turbolinks:load', guardianFields);
