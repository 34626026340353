var setupTableFilter = function() {
    $('.js-filter-toggle').click(function(toggle) {
      $(toggle.target).next().toggleClass('active');
    });

  // close filter if clicking outside hover
  $(document).click(function(e) {
    var clickIsFilter = $(e.target).closest('.table-filters').length > 0;
    var clickIsPicker = $(e.target).closest('.ui-datepicker-header').length > 0;

    // Don't close if click is the filter itself, or a date picker month pager
    if (clickIsFilter || clickIsPicker) return;

    $('.table-filters').each(function(i, el) {
      if ($(el).prev()[0] != e.target) {
        $(el).removeClass('active');
      }
    });
  });
};

$(document).on('turbolinks:load', setupTableFilter);
