// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('bootstrap/dist/js/bootstrap');
require('trix');
require('@rails/actiontext');
require('selectize');
require('jquery-mask-plugin');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'cocoon-js';
import '@fortawesome/fontawesome-pro/js/all';
import '../stylesheets/application';
import '../src/alert_confirm_modals';
import '../src/color_picker';
import '../src/direct_upload';
import '../src/filing_status';
import '../src/guardian_fields';
import '../src/income_requirements_form';
import '../src/masked_input';
import '../src/modal_forms';
import '../src/prescreen';
import '../src/notifications';
import '../src/quarter_select';
import '../src/selectize';
import '../src/school_select';
import '../src/school_year_select';
import '../src/search_field';
import '../src/show_spa_modal';
import '../src/table_filter';
import '../src/test_scores_exception_modal';
import '../src/withdrawal_modal';
import $ from 'jquery';
global.$ = jQuery;
