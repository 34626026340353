import Pickr from '@simonwep/pickr';

jQuery(document).on('turbolinks:load', () => {
  jQuery('.color-picker').each((i, el) => {
    Pickr.create({
      el: el,
      theme: 'monolith',
      useAsButton: true,
      default: el.value || '#ff0000',
      lockOpacity: true,
      components: {
        preview: true,
        hue: true,
        interaction: {
          input: true,
          save: true
        }
      }
    }).on('save', color => {
      el.value = color.toHEXA().toString();
    });
  });
});
