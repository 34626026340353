const filingStatus = () => {
  jQuery("input[name='household_application[filing_status]']:radio").change(
    e => {
      const val = jQuery(
        "input[name='household_application[filing_status]']:checked"
      ).val();
      const form = e.target.form;
      jQuery('.js-income-subform', form).addClass('d-none');
      jQuery('.js-income-subform fieldset', form).prop('disabled', true);
      switch (val) {
        case 'filed':
          jQuery('#js-state-taxes-form', form).removeClass('d-none');
          jQuery('#js-state-taxes-form fieldset', form).prop('disabled', false);
          break;
        case 'nonfiling_1099':
          jQuery('#js-1099W-2-form', form).removeClass('d-none');
          jQuery('#js-1099W-2-form fieldset', form).prop('disabled', false);
          break;
        case 'nonfiling_1040':
          jQuery('#js-1040-form', form).removeClass('d-none');
          jQuery('#js-1040-form fieldset', form).prop('disabled', false);
          break;
        case 'no_wages':
          jQuery('#js-no-wages-form', form).removeClass('d-none');
          jQuery('#js-no-wages-form fieldset', form).prop('disabled', false);
          break;
      }
    }
  );
};

jQuery(document).on('turbolinks:load', filingStatus);
