require('bootstrap/dist/js/bootstrap');

jQuery(document).on('turbolinks:load', () => {
  jQuery('.show-spa-modal').on('click', event => {
    event.preventDefault();

    $.ajax(
      '/school_admin/agreements/' + jQuery(event.target).data('agreementid'),
      {
        method: 'GET'
      }
    ).then(res => {
      $('#show-spa-modal-container').html(res);
      $('#show-spa-modal').modal({ show: true });
    });
  });
});
