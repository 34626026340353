global.modalFormsInit = function() {
  const hideModal = function() {
    $('.js-modal-form').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  };

  $('.js-modal-form').on('submit', hideModal);
  return { hideModal };
};

$(document).on('turbolinks:load', modalFormsInit);
