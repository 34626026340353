require('bootstrap/dist/js/bootstrap');

jQuery(document).on('turbolinks:load', () => {
  const $testScoresModal = $('#test-scores-exception-modal');
  $testScoresModal.modal({
    show: false
  });

  const $showLink = $('#show-exception-modal');

  $showLink.on('click', event => {
    event.preventDefault();

    const studentName = $showLink
      .parents('.row')
      .find('.student-name')
      .text();
    const scholarshipId = $showLink
      .parents('.row')
      .find('.test-score-scholarship-id')
      .val();

    $testScoresModal
      .find('h5')
      .text('Test scores unavailable for ' + studentName);
    $testScoresModal.find('.test-score-scholarship-id').val(scholarshipId);

    $testScoresModal.modal('show');
  });
});
