import Rails from '@rails/ujs';
import { appendFile } from 'fs';

const showAlertDialog = element => {
  const title = element.getAttribute('data-alertTitle') || 'Warning!';
  const content = element.getAttribute('data-alert');
  const extra = extraContent(element);
  $('#alertModal .modal-title').text(title);
  $('#alertModal .modal-body p').text(content);
  $('#alertModal .modal-body div').html(extra || '');

  $('#alertModal').modal('show');
};

const showConfirmationDialog = element => {
  const title = element.getAttribute('data-confirmTitle') || 'Are you sure?';
  const content = element.getAttribute('data-confirm');
  const extra = extraContent(element);

  $('#confirmModal .modal-title').text(title);
  $('#confirmModal .modal-body p').text(content);
  $('#confirmModal .modal-body div').html(extra || '');

  $('#confirmModal').modal('show');
  $('#confirmModal').on('click', '.btn-primary', () => {
    $('#confirmModal').modal('hide');
    element.removeAttribute('data-confirm');
    element.click();
  });
};

const allowAction = element => {
  if (
    element.getAttribute('data-confirm') === null &&
    element.getAttribute('data-alert') === null
  ) {
    return true;
  }

  if (element.getAttribute('data-confirm') !== null) {
    showConfirmationDialog(element);
  } else {
    showAlertDialog(element);
  }

  return false;
};

function handleAlertConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

var extraContent = function(target) {
  if (
    $(target).val() === 'Accept' &&
    ($(target.form).attr('id') || '').includes('edit_scholarship')
  ) {
    return acceptScholarshipConfirmationDialog(target);
  }
  return '';
};

var acceptScholarshipConfirmationDialog = function(target) {
  const $form = $(target.form);
  const $target = $(target);
  const tuition = $form
    .parent()
    .find('input[name="scholarship[tuition]"]')
    .val();
  const fees = $form
    .parent()
    .find('input[name="scholarship[fees]"]')
    .val();
  const enrollmentDate = $form
    .parent()
    .find('input[name="scholarship[enrollment_date]"]')
    .val();
  return `<div>Tuition: $${tuition}</div><div>Fees: $${fees}</div><div>First day of school: ${enrollmentDate}</div>`;
};

Rails.delegate(document, 'a[data-confirm]', 'click', handleAlertConfirm);
Rails.delegate(document, 'button[data-confirm]', 'click', handleAlertConfirm);
Rails.delegate(document, 'input[data-confirm]', 'click', handleAlertConfirm);
Rails.delegate(document, 'a[data-alert]', 'click', handleAlertConfirm);
