// direct_uploads.js
global.directUploadsInit = function() {
  addEventListener('direct-upload:initialize', event => {
    const { target, detail } = event;
    const { id, file } = detail;
    if (!$(`#direct-upload-${id}`)[0]) {
      target.insertAdjacentHTML(
        'beforebegin',
        `
        <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
          <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
          <span class="direct-upload__filename">${file.name}</span>
        </div>
      `
      );
    }
  });

  addEventListener('direct-upload:start', event => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload--pending');
  });

  addEventListener('direct-upload:progress', event => {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(
      `direct-upload-progress-${id}`
    );
    progressElement.style.width = `${progress}%`;
  });

  addEventListener('direct-upload:error', event => {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
  });

  addEventListener('direct-upload:end', event => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
  });

  var onFileSelected = function(evt) {
    const fileInput = evt.target;
    const files = Array.from(evt.target.files).map(file => file.name);
    const placeholder =
      fileInput.getAttribute('data-placeholder') || fileInput.placeholder;

    // Store original placeholder
    fileInput.setAttribute('data-placeholder', placeholder);

    // Update current placeholder
    if (files.length === 1) {
      fileInput.placeholder = files[0].replace(/.*[\/\\]/, '');
    } else if (files.length > 1) {
      fileInput.placeholder = `${files.length} files`;
    } else {
      fileInput.placeholder = placeholder;
    }
  };

  jQuery('input[type="file"]').each((index, el) =>
    jQuery(el).on('change', onFileSelected)
  );
};

jQuery(document).on('turbolinks:load', directUploadsInit);
