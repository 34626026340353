jQuery(document).on('turbolinks:load', () => {
  const addRow = () => {
    const numRows = jQuery('table.income-requirements tbody tr').length;
    const row = `<tr>
      <td>
        <div class="form-group mb-0">
          <input name="school_year[income_requirements_attributes][${numRows}][household_size]" type="number" class="form-control" />
        </div>
      </td>

      <td>
        <div class="form-group mb-0">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>

            <input name="school_year[income_requirements_attributes][${numRows}][poverty_guideline]" type="text" class="form-control poverty-guideline" />
          </div>
        </div>
      </td>

      <td class="align-middle">
        <div class="form-group mb-0 max-income-new">
          <span></span>
          <input name="school_year[income_requirements_attributes][${numRows}][max_income_new]" type="hidden" />
        </div>
      </td>

      <td class="align-middle">
        <div class="form-group mb-0 max-income-renew">
          <span></span>
          <input name="school_year[income_requirements_attributes][${numRows}][max_income_renew]" type="hidden" />
        </div>
      </td>

      <td class="align-middle">
        <a href="#" class="text-danger remove-income-requirement">Delete</a>
      </td>`;

    jQuery('table.income-requirements tbody').append(row);
  };

  jQuery('table.income-requirements').on(
    'click',
    '.remove-income-requirement',
    event => {
      event.preventDefault();

      jQuery(event.target)
        .parents('tr')
        .remove();
    }
  );

  const maxNewPctInput = jQuery('#school_year_new_max_pct');
  const maxRenewPctInput = jQuery('#school_year_renew_max_pct');

  const parseCurrencyInput = str => parseInt(str.replace(/\$|\.|,/g, ''));
  const parsePctInput = str => parseInt(str) / 100;

  const updateIncomeValues = () => {
    jQuery('table.income-requirements tbody tr').each((i, row) => {
      const povertyGuideline = parseCurrencyInput(
        jQuery('input.poverty-guideline', row).val()
      );
      const maxNew = Math.ceil(
        povertyGuideline * parsePctInput(maxNewPctInput.val())
      );
      const maxRenew = Math.ceil(
        povertyGuideline * parsePctInput(maxRenewPctInput.val())
      );

      jQuery('.max-income-new span', row).text(
        maxNew.toLocaleString('en', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      );
      jQuery('.max-income-new input', row).val(maxNew);
      jQuery('.max-income-renew span', row).text(
        maxRenew.toLocaleString('en', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      );
      jQuery('.max-income-renew input', row).val(maxRenew);
    });
  };

  maxNewPctInput.on('change', updateIncomeValues);
  maxRenewPctInput.on('change', updateIncomeValues);

  jQuery('table.income-requirements').on(
    'change',
    '.form-control.poverty-guideline',
    updateIncomeValues
  );

  jQuery('.add-income-requirement').on('click', event => {
    event.preventDefault();

    addRow();
  });
});
